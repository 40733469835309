const strings = {
    NAO_FOI_POSSIVEL_PROCESSAR_ESTA_REQUISICAO: "Não foi possível processar esta requisição",
    OCORREU_UM_ERRO_AO_TENTAR_PROCESSAR_ESSA_REQUISICAO: "Ocorreu um erro ao tentar processar essa requisição",
    NAO_HA_NENHUM_REGISTRO_PARA_REALIZAR_A_EXPORTACAO: "Não há nenhum registro para realizar a exportação",
    CAMPO_OBRIGATORIO: "Campo obrigatório",
    O_TAMANHO_DO_ARQUIVO_EXCEDE_O_LIMITE_DE_128_MB: "O tamanho do arquivo excede o limite de 128 MB",
    TIPO_DE_ARQUIVO_NAO_SUPORTADO: "Tipo de arquivo não suportado",
    NENHUM_ARQUIVO_SELECIONADO: "Nenhum arquivo selecionado",
}

export default strings;