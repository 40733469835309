import { checkLogin } from './Login';
import {
    getGestaoList,
    getGestaoIssuesList,
    listOrganizationClients, 
    setMaintenanceWarning,
    getWarningList, 
    closeMaintenance, 
    sendMail, 
    loadClientPanel,
    loadClientDocument,
    loadClientEvent,
    loadMaintenance,
    getMaintenanceDoc,
    getChild,
    listClientActiveSystemsTypes,
    saveMaintenance,
    setMaintenanceDoc,
    setMaintenanceEvent,
    listDocumentTypes,
    getDocInfosByNC,
    closeMaintenanceDoc,
    saveDocument,
    listClientAddresses,
    listOrganizations,
    listMaintenanceTypes,
    getClientList,
    getQuantTratativasOp,
    getCountTratativas,
    getSupervisaoMaintenance,
    downloadMaintenance,
    deleteMaintenanceWarning
} from './Gestao';

const baseApi = process.env.REACT_APP_API_URL;
const auth = process.env.REACT_APP_BEARER;

export {
    baseApi,
    auth,
    checkLogin,
    getGestaoList,
    getGestaoIssuesList,
    listOrganizationClients,
    setMaintenanceWarning,
    getWarningList,
    closeMaintenance,
    sendMail,
    loadClientPanel,
    loadClientDocument,
    loadClientEvent,
    loadMaintenance,
    getMaintenanceDoc,
    getChild,
    listClientActiveSystemsTypes,
    saveMaintenance,
    setMaintenanceDoc,
    setMaintenanceEvent,
    listDocumentTypes,
    getDocInfosByNC,
    closeMaintenanceDoc,
    saveDocument,
    listClientAddresses,
    listOrganizations,
    listMaintenanceTypes,
    getClientList,
    getQuantTratativasOp,
    getCountTratativas,
    getSupervisaoMaintenance,
    downloadMaintenance,
    deleteMaintenanceWarning
};