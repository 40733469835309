import axios from "axios";
import { baseApi, auth } from "./Api";

const getGestaoList = async (data) => {
    var res;
    await axios.post(`${baseApi}/system/getGestaoList`,data,{
        headers:{
            'Authorization': `Bearer ${auth}`,
            'Content-type': 'application-json'
        }
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const getGestaoIssuesList = async (data) => {
    var res;
    await axios.post(`${baseApi}/system/getGestaoIssuesList`,data,{
        headers:{
            'Authorization': `Bearer ${auth}`,
            'Content-type': 'application-json'
        }
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const listOrganizationClients = async (orgId = null) => {
    var res;
    var requestUri = `${baseApi}/system/getUnityWarning` + (orgId !== "null" && orgId != null && orgId.length >= 0 ? `/${Number(orgId)}` : '');
    await axios.get(requestUri,{
        headers:{
            'Authorization': `Bearer ${auth}`,
            'Content-type': 'application-json'
        }
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const setMaintenanceWarning = async (data) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'multipart/form-data'
    }
    await axios.post(`${baseApi}/system/setMaintenanceWarning`,JSON.stringify(data),{headers}
    ).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const deleteMaintenanceWarning = async (data) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'multipart/form-data'
    }
    await axios.post(`${baseApi}/system/deleteMaintenanceWarning`,JSON.stringify(data),{headers}
    ).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const getWarningList = async (id) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`
    }
    await axios.get(`${baseApi}/system/getWarningList/${id}`,{headers},{
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const closeMaintenance = async (data) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    }
    await axios.post(`${baseApi}/system/setConclusionAndWarning`,data,{headers}
    ).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const sendMail = async (id,type) => {
    var res;
    await axios.get(`${baseApi}/email/sendEmail/${id}/${type}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`,
      },
    }).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res
}

const loadClientPanel = async (clientId) => {
    var res;
    await axios.get(`${baseApi}/clients/getPanel/${clientId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`,
      },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const loadClientDocument = async (clientId, daysBefore) => {
    var res;
    await axios.get(`${baseApi}/clients/getClientDocuments/${clientId}/${daysBefore}`, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`,
      },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const loadClientEvent = async (clientId, daysBefore) => {
    var res;
    await axios.get(`${baseApi}/clients/getClientEvents/${clientId}/${daysBefore}`, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`,
      },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const loadMaintenance = async (issueId) => {
    var res;
    if (!issueId) return res;
    await axios.get(`${baseApi}/system/getMaintenance/${issueId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`,
      },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const getMaintenanceDoc = async (document_id) => {
    var res;
    await axios.get(`${baseApi}/documents/getMaintenanceDoc/${document_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`,
      },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const getChild = async (clientId) => {
    var res;
    await axios.get(`${baseApi}/clients/getChild/${clientId}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const listClientActiveSystemsTypes = async (clientId) => {
    var res;
    await axios.get(`${baseApi}/system/getClientActiveSystemType/${clientId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const saveMaintenance = async (postData) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    }
    await axios.post(`${baseApi}/system/setMaintenance`,postData,{headers}
    ).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const setMaintenanceDoc = async (postData) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    }
    await axios.post(`${baseApi}/documents/setMaintenanceDoc`,postData,{headers}
    ).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const setMaintenanceEvent = async (postData) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    }
    await axios.post(`${baseApi}/documents/setMaintenanceEvent`,postData,{headers}
    ).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const listDocumentTypes = async (system_maintenance_id) => {
    var res;
    await axios.get(`${baseApi}/documents/getDocumentTypes/${system_maintenance_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const getDocInfosByNC = async (system_maintenance_id) => {
    var res;
    await axios.get(`${baseApi}/documents/getDocInfosByNC/${system_maintenance_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const closeMaintenanceDoc = async (postData) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    }
    await axios.post(`${baseApi}/documents/setConclusionAndWarningNCdoc`,postData,{headers}
    ).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const saveDocument = async (postData) => {
    var res;
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    }
    await axios.post(`${baseApi}/documents/saveDocument`,postData,{headers}
    ).then((response) => {
        res = response;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const listClientAddresses = async (clientId) => {
    var res;
    await axios.get(`${baseApi}/clients/getClientAddress/${clientId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const listOrganizations = async () => {
    var res;
    await axios.get(`${baseApi}/clients/getOrganization`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const listMaintenanceTypes = async (sysId) => {
    var res;
    var requestUri = `${baseApi}/system/getMaintenanceType`;
    if (sysId)
        requestUri += `/${sysId}`;

    await axios.get(requestUri, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const getClientList = async (orgId) => {
    var res;
    await axios.get(`${baseApi}/organizations/getClientList/${orgId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const getQuantTratativasOp = async (postData) => {
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.post(`${baseApi}/system/getQuantTratativasOperador`, postData, { headers });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const getCountTratativas = async () => {
    var res;
    await axios.get(`${baseApi}/system/getCountTratativas`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((response) => {
        res = response.data;
    }).catch((error) => {
        res = error;
    });
    return res;
}

const getSupervisaoMaintenance = async (postData) => {
    const headers = {
        'Authorization': `Bearer ${auth}`,
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.post(`${baseApi}/system/getMaintenanceSupervisao`, postData, { headers });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const downloadMaintenance = async (id) => {
    try {
        const req = await fetch(`${baseApi}/system/downloadFile/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${auth}`,
                'Accept': 'application/pdf, image/*, application/json'
            }
        });

        if (!req.ok) {
            throw new Error(`HTTP error! status: ${req.status}`);
        }

        const res = await req.json();
        const data = atob(res.data);
        const mimeType = res.mimeType;

        const byteCharacters = atob(res.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: mimeType });

        // Retorna o arquivo e as propriedades
        return { blob, mimeType, url: res.url };
    } catch (e) {
        console.log(e);
        return null;
    }
}

export{
    getGestaoList,
    getGestaoIssuesList,
    listOrganizationClients,
    setMaintenanceWarning,
    getWarningList,
    closeMaintenance,
    sendMail,
    loadClientPanel,
    loadClientDocument,
    loadClientEvent,
    loadMaintenance,
    getMaintenanceDoc,
    getChild,
    listClientActiveSystemsTypes,
    saveMaintenance,
    setMaintenanceDoc,
    setMaintenanceEvent,
    listDocumentTypes,
    getDocInfosByNC,
    closeMaintenanceDoc,
    saveDocument,
    listClientAddresses,
    listOrganizations,
    listMaintenanceTypes,
    getClientList,
    getQuantTratativasOp,
    getCountTratativas,
    getSupervisaoMaintenance,
    downloadMaintenance,
    deleteMaintenanceWarning
}