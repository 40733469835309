import { DocExtension } from "../Components/Enums/FileExtensions";
import strings from "./StringsHelpers";

const utils = {
    isNullOrEmpty: (value) => {
        return value === null || value === '';
    },

    blobToString: async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsBinaryString(blob);
        });
    },

    checkFileDocType: (file) => {
        const maxSize = 128 * 1024 * 1024; // 128MB
        const formats = DocExtension;

        if (file.size > maxSize) {
            return {
                isValid: false,
                message: strings.O_TAMANHO_DO_ARQUIVO_EXCEDE_O_LIMITE_DE_128_MB
            }           
        }else if (!formats.includes(file.name.split('.').pop().toLowerCase())) {
            return {
                isValid: false,
                message: strings.TIPO_DE_ARQUIVO_NAO_SUPORTADO
            }
        }

        return {
            isValid: true,
            message: ""
        }
    },
};
  
export default utils;