import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import moment from 'moment';
import { DocExtension } from '../Enums/FileExtensions';
import utils from '../../Helpers/FunctionsHelpers';
import strings from '../../Helpers/StringsHelpers';

import { listDocumentTypes, getDocInfosByNC, closeMaintenanceDoc, saveDocument } from '../Api/Api';

export default function IssueClosureModalDocExpireModal({ show = false, close , system_maintenance_id, client_id }) {
    const fileUpload = useRef();
    const msgArchiveEmpty = strings.NENHUM_ARQUIVO_SELECIONADO;
    const docExtension = DocExtension.map(ext => `.${ext}`).join(',');
    
    const { handleSubmit, register, reset } = useForm();
    const [docTypes, setDocTypes] = useState([]);
    const [docInfos, setDocInfos] = useState([]);
    const [fileSelected, setFileSelected] = useState();
    const [fileDesc, setFileDesc] = useState(msgArchiveEmpty);
    const [isFileSelected, setIsFileSelected] = useState(false);

    const showFileUpload = () => {
        fileUpload.current.click();
    }

    const handleClose = () => {
        reset();
        handleClearFile();
        close();
    }

    const handleClearFile = () => {
        if (fileUpload.current) {
          fileUpload.current.value = "";
        }
    
        setFileSelected(null);
        setIsFileSelected(false);
        setFileDesc(msgArchiveEmpty);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        let desc = file ? file.name : msgArchiveEmpty;
    
        const validatedFile = utils.checkFileDocType(file);
    
        if(!validatedFile.isValid){
          Swal.fire("OPS!", validatedFile.message, "error");
          setFileSelected(null);
          setIsFileSelected(false);
          setFileDesc(msgArchiveEmpty);
          return;
        }
    
        setFileSelected(file);
        setIsFileSelected(true);
        setFileDesc(desc);
    }

    const loadDocTypes = async () => {
        Swal.fire({
            backdrop: true,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                const res = await listDocumentTypes();
                setDocTypes(res.data);
                Swal.close();
            },
        })
    }

    const getDocInfosByNc = async (system_maintenance_id) => {
        Swal.fire({
            backdrop: true,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                const res = await getDocInfosByNC(system_maintenance_id);
                setDocInfos(res.data);
                reset(res.data);
                Swal.close();
            },
        })
    }
    
    const save = (data) => {
        if (!isFileSelected) {
            Swal.fire("OPS!", strings.NENHUM_ARQUIVO_SELECIONADO, "error");
            return;
        }
        
        Swal.fire({
            backdrop: true,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();

                const formData = new FormData();
                Object.keys(data).forEach(k => {
                    formData.append(k, data[k]);
                })
                formData.append('file', fileSelected);
                formData.append('client_id', client_id);

                const res = await saveDocument(formData);

                if (res.data.result) {
                    formData.append('user_id', sessionStorage.getItem('gestao_userId'));
                    formData.append('doc_id', res.data.id);
                    formData.append('nc_id', system_maintenance_id);

                    const res2 = await closeMaintenanceDoc(formData);

                    if (res2.data.result) {
                        Swal.fire({
                            title: 'NC encerrada com sucesso!',
                            icon: 'success',
                            allowOutsideClick: false,
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Swal.close();
                                handleClose();
                            }
                        });
                    }else{
                        Swal.fire('OPS!', strings.OCORREU_UM_ERRO_AO_TENTAR_PROCESSAR_ESSA_REQUISICAO, 'error');
                        handleClose();
                    }

                }else{
                    Swal.fire('OPS!', strings.OCORREU_UM_ERRO_AO_TENTAR_PROCESSAR_ESSA_REQUISICAO, 'error');
                    handleClose();
                }
            }
        });
    }

    useEffect(() => {
        if (!show) return;
    
        loadDocTypes();
        if (system_maintenance_id) {
            getDocInfosByNc(system_maintenance_id);
        }
    }, [show, system_maintenance_id, client_id]);
    
    return (
        <Modal className='modal-padding' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Encerramento da NC #{system_maintenance_id?.padStart(5, '0')}
                    <br/>
                    <h6 className="text-white-50 m-0">Vínculo com documentação a vencer</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(save)}>
                    <h6 className="mb-3 mt-2">TRATATIVA</h6>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-group">
                                <textarea
                                    rows="2"
                                    className="form-control resize-none"
                                    placeholder="Descrição *"
                                    {...register('descTratNC')}
                                    required={true}
                                >                
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-group">
                                <input 
                                    className="form-control" 
                                    placeholder="Nome do encarregado *" 
                                    {...register('encarregado')}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                    <h6 className="my-4">DOCUMENTAÇÃO</h6>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-floating">
                                <select className="form-select" aria-label="Tipo de Documento *" {...register('document_type_id')} required={true}>
                                    <option value="">Selecione</option>
                                    {
                                    docTypes.length > 0 &&
                                    docTypes.map((item, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={item.document_type_id}
                                                selected={item.document_type_id === docInfos?.document_type_id}
                                            >
                                                {item.document_type_name}
                                            </option>
                                        )
                                    })
                                    }
                                </select>
                                <label htmlFor="type">Tipo de Documento *</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-floating">
                                <input type="date" max={moment().format("YYYY-MM-DD")} className="form-control" {...register('document_emitted')} required={true} />
                                <label>Data de Emissão *</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-floating">
                                <input type="date" min={moment().format("YYYY-MM-DD")} className="form-control" {...register('document_expiration')} required={true} />
                                <label>Data de Validade *</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-floating">
                            <input type="text" className="form-control" placeholder="Nome do Documento *" {...register('document_name')} required={true} />
                                <label>Nome do documento *</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-group">
                                <textarea
                                    rows="3"
                                    className="form-control resize-none"
                                    placeholder="Descrição"
                                    {...register('document_description')}
                                >                
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="form-group">
                                <h6>- São aceitas as extensões <b>{DocExtension.map(ext => `.${ext}`).join(' ')}</b></h6>
                                <h6 className="mb-3">- Tamanho máximo de 128MB</h6>
                                <input className="d-none" type="file" onChange={handleFileChange} accept={docExtension} id="my_file" ref={fileUpload} />
                                <div className="input-group">
                                    <input type="text" name="" className="form-control" id="fileDesc" readOnly value={fileDesc} />
                                    <button onClick={handleClearFile} type="button" className={isFileSelected ? "btn btn-secondary" : "btn btn-secondary disabled"}>
                                        <i className='bi bi-trash'></i>
                                    </button>
                                    <button onClick={showFileUpload} type="button" className="btn btn-primary">
                                        <i className='bi bi-upload'></i> Carregar arquivo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button className='btn btn-danger' type="submit">Encerrar</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}